<template>
  <div>
    <TopMenuSubheadline :title="title"/>
    <div v-if="!isBookLoading && !isReviewLoading">
      <div class="app-content-box bg-gray">
        <div class="wrapper container">
          <div class="row mt-4">
            <div class="col-3">
              <img :src="coverUrl + book.image" :alt="book.title" class="w-100">
            </div>
            <div class="col-9 pl-0">
              <h3 class="book-side-title-alt">{{book.tytul}}</h3>
              <p class="book-side-author-alt">{{book.autor}}</p>
            </div>
          </div>
        </div>
        <div class="mt-3 mb-4 rating-wrapper">
          <star-rating :increment="0.5" active-color="#000"
            inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
            :border-width='borderWidth' :animate='animate' :show-rating="false" :star-size=35
            :rounded-corners='rounded' :rating="rating" @rating-selected ="setRating">
          </star-rating>
        </div>
        <div class="text-center mt-2">
          <div>Ocena: {{avgRating}}</div>
          <div v-if="myRating">Twoja ocena: {{myRating}}</div>
        </div>
        <div class="container mt-3">
          <div class="edit-wrapper">
            <textarea ame="" id="" placeholder="napisz recenzję" class="edit-review" v-model="review.content"></textarea>
            <button type="submit" @click="submit"><i class="fas fa-paper-plane"></i></button>
          </div>
        </div>
        <ModalSlot title="Dodaj książkę" v-if="isPopupShown" @close-modal="closeModal(false)">
          <h3 class="text-center mb-4 modal-main-title">Ocena i opinia zostały dodane</h3>
          <div><strong>Dziękujemy, za podzielenie się swoim zdaniem.</strong></div>
          <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Zamknij</button>
        </ModalSlot>
    </div>
    </div>
    <div v-else>
      <Loader />
    </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import ModalSlot from '../components/ModalSlot'
import Loader from '../components/Loader'
import StarRating from 'vue-star-rating'
export default {
  name: 'ReviewEdit',
  components: {
    TopMenuSubheadline,
    Loader,
    StarRating,
    ModalSlot
  },
  props: {
    id: [String, Number],
    book_id: [String, Number]
  },
  async created () {
    this.$https('/book/' + this.book_id + '/review/' + this.id, {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
      }
    })
      .then(({ data }) => {
        this.review = data.response.rewiev
        this.isReviewLoading = false
      })
      .catch(error => {
        console.log('', error)
      })
    await this.getBook()
  },
  data () {
    return {
      title: 'edytuj recenzje',
      message: '',
      reviewContent: '',
      book: [],
      review: [],
      rating: 0,
      avgRating: 0,
      myRating: 0,
      borderWidth: 2,
      animate: true,
      rounded: true,
      revInfo: '',
      isBookLoading: true,
      isReviewLoading: true,
      isPopupShown: false
    }
  },
  methods: {
    getBook () {
      this.$https('/book/' + this.book_id, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        }
      })
        .then(({ data }) => {
          this.book = data.response.book
          this.myRating = this.book.myRating
          if (this.avgRating % 1 === 0) {
            this.avgRating = parseFloat(this.book.rating, 10)
          } else {
            this.avgRating = parseFloat(this.book.rating, 10)
            this.avgRating = this.avgRating.toFixed(1)
          }
          this.rating = parseFloat(this.book.rating, 10)
          this.isBookLoading = false
        })
        .catch(error => {
          console.log('', error)
        })
    },
    closeModal (value) {
      this.isPopupShown = value
      this.$router.push(`/recenzja/${this.revInfo.book_id}/${this.revInfo.id}`)
    },
    submit () {
      const getData = `content=${this.review.content}`
      this.$https('/book/' + this.book_id + '/review/' + this.id, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.revInfo = response.data.response
        this.message = ''
        this.isPopupShown = true
      })
        .catch(error => {
          console.log('', error)
        })
    },
    setRating (rating) {
      const getData = `value=${rating}`
      this.$https('/rate/book/' + this.book_id, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.getBook()
      })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
  }

  .wrapper {
    padding-top: 30px;
  }

  .rating-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

.edit-wrapper {
    width: 100%;
    height: 45vh;
    display: flex;
    background-color: #fff;
    border-radius: 5%;
    align-items: flex-end;
    padding: 8px;
    border: 1px solid #d0d0d0;

    button {
    border: none;
    background: none;
    font-size: 16px;
    line-height: 0px;
    padding: 50px 10px 0px 5px;
    color: #000;
    z-index: 2;
  }
  textarea {
    border: none;
    resize: none;
    padding: 8px 6px;
    width: 100%;
    height: 100%;
    font-size: 11px;
    line-height: 15px;
  }
}

@media screen and (max-width: 320px) {
  .edit-wrapper {
    width: 100%;
    height: 35vh;
    display: flex;
    background-color: #fff;
    border-radius: 5%;
    align-items: flex-end;
    padding: 8px;
    border: 1px solid #d0d0d0;
    }
}
  .floating-add-comment,
  .floating-msg-box {
    position: absolute;
    z-index: 1;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid transparent;
    padding: 7px 0;
    background: #f1f1f1;
    top: 220px;
    left: 0;

    .add-comment-box,
    .add-msg-box {

      .add-comment-group,
      .add-msg-group {
        position: relative;

        button[type="submit"] {
          position: absolute;
          bottom: 0;
          right: 0;
          border: 0;
          background: none;
          font-size: 16px;
          line-height: 0px;
          padding: 50px 10px 0px 5px;
          color: #d0d0d0;
          z-index: 2;
        }
        textarea {
          transition: height .2s ease;
          position: relative;
          z-index: 0;
          border: 1px solid #d0d0d0;
          padding: 8px 15px 8px 10px;
          border-radius: 15px;
          color: #d0d0d0;
          width: 100%;
          max-width: 100%;
          font-size: 11px;
          line-height: 15px;
          height: 60vh;
          resize: none;

          &::-webkit-input-placeholder { /* Edge */
            color: #d0d0d0;
            opacity: 1;
          }
          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #d0d0d0;
            opacity: 1;
          }
          &::placeholder {
            color: #d0d0d0;
            opacity: 1;
          }

          &:not(:placeholder-shown) {
            color: #000;

            & ~ button[type="submit"] {
              color: #000;
            }
          }
        }
      }
    }
}
</style>
